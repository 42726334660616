import React from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
  
      <header className="App-header">
      <t
      className="title"
      >
      GiBRO
      </t>
        <img src={logo} className="App-logo" alt="logo" />
        <p>
        Gibro IOT website under development
        </p>
        <a
          className="App-link"
        >
          Powered by React
        </a>
        <b
        className="footer"
        >
        <a href="https://www.linkedin.com/in/eusebio-gichuki-githii">DevChebio</a>
        
          
        </b>
        
      </header>

      
    </div>
  );
}

export default App;
